@import 'src/less/colors';

.summaryContent {
  margin-top: 12px;
  border-left: 2px solid @PrimaryCobalt;
  padding-left: 12px;
  margin-left: 8px;
}

.clientIdExample {
  margin: 12px 0px;
}

.fakeInput {
  background-color: @PrimaryCobalt05;
  padding: 8px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: normal;
  border: 2px solid @PrimaryCobalt05;
  font-family: Graphik, sans-serif;
  white-space: nowrap;

  .urlPart1 {
    color: @PrimaryCobalt60;
  }

  .urlPart2 {
    color: @PrimaryCobalt;
  }
}

.highlight {
  font-weight: 500;
}
