@import 'src/less/colors';

.profileSharedFieldsContainer {
  margin-top: 20px;
  padding: 16px;
  background-color: @SecondarySkyBlue10;
  border: 1px solid @SecondarySkyBlue20;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  h4 {
    color: @PrimaryCobalt;
    margin-bottom: 4px;
    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  .profileSharedField {
    display: flex;
    gap: 8px;
    align-items: center;
    font-family: Graphik, sans-serif;

    .profileSharedFieldName {
      color: @PrimaryCobalt;
      font-size: 14px;
      font-weight: 500;
      width: 120px;
    }

    .profileSharedFieldValue {
      color: @PrimaryCobalt;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
