/*
 * colors.less
 */
.summaryContent {
  margin-top: 12px;
  border-left: 2px solid #1f2e77;
  padding-left: 12px;
  margin-left: 8px;
}
.clientIdExample {
  margin: 12px 0px;
}
.fakeInput {
  background-color: #f3f4f8;
  padding: 8px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: normal;
  border: 2px solid #f3f4f8;
  font-family: Graphik, sans-serif;
  white-space: nowrap;
}
.fakeInput .urlPart1 {
  color: #7982ad;
}
.fakeInput .urlPart2 {
  color: #1f2e77;
}
.highlight {
  font-weight: 500;
}
