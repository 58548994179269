/*
 * colors.less
 */
.clientModes {
  margin-bottom: 24px;
}
.clientModes .clientModesRow:not(:last-of-type) {
  margin-bottom: 16px;
}
.clientModeButton {
  display: inline-block;
  margin-right: 16px;
}
.permissionsContainer {
  margin-bottom: 48px;
}
.permissionsContainer .permissionContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 12px 8px;
  border-radius: 8px;
}
.permissionsContainer .permissionContainer:hover {
  background-color: #e2ebff;
}
.permissionsContainer .permissionContainer .toggle {
  margin-bottom: 0px;
}
.permissionsContainer .permissionContainer .permissionTitle {
  color: #a5abc9;
  font-weight: 500;
  font-size: 14px;
}
.permissionsContainer .permissionContainer .permissionTitle.active {
  color: #1f2e77;
}
