/*
 * colors.less
 */
.profileSharedFieldsContainer {
  margin-top: 20px;
  padding: 16px;
  background-color: #f1f6ff;
  border: 1px solid #e2ebff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.profileSharedFieldsContainer h4 {
  color: #1f2e77;
  margin-bottom: 4px;
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
.profileSharedFieldsContainer .profileSharedField {
  display: flex;
  gap: 8px;
  align-items: center;
  font-family: Graphik, sans-serif;
}
.profileSharedFieldsContainer .profileSharedField .profileSharedFieldName {
  color: #1f2e77;
  font-size: 14px;
  font-weight: 500;
  width: 120px;
}
.profileSharedFieldsContainer .profileSharedField .profileSharedFieldValue {
  color: #1f2e77;
  font-size: 14px;
  font-weight: 400;
}
